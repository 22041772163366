//MY VERSION THAT WAS PREVIOUSLY WORKING WITH ARROWS BELOW
//ADDING ITEM DID NOT IMMEDIATELY APPEAR IN WINDOW

import React, { useState, useEffect, useCallback } from "react";
import readFromDb from "./readFromDb"; // Adjust the path to your Firebase functions
import AutoResizeTextarea from "./AutoResizeTextarea";
import "./PaginatedList.css";

const PaginatedList = ({ entity, registerChildFunction }) => {
  const [items, setItems] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paginationHistory, setPaginationHistory] = useState([]);

  useEffect(() => {
    registerChildFunction(fetchData);
  }, [registerChildFunction]);

  const fetchItems = async (entity, lastVisible) => {
    setLoading(true);
    const result = await readFromDb(entity, lastVisible);
    setItems(result.data);
    setLastVisible(result.lastVisible);
    setLoading(false);
    return result;
  };
  //Do I Need below?
  // useEffect(() => {
  //   const resetAndFetch = async () => {
  //     const result = await fetchItems(entity, null);
  //     setItems(result.data);
  //     setLastVisible(result.lastVisible);
  //     setLoading(false);
  //     setPaginationHistory([result.lastVisible]); // Reset pagination history
  //   };
  //   if (currentPage === 1) {
  //     resetAndFetch();
  //   }
  // }, [entity]);

  const fetchData = async (entity, lastVisible) => {
    if (currentPage > 1) {
      let lastDoc = paginationHistory[currentPage - 2];
      const result = await fetchItems(entity, lastDoc);

      if (currentPage > paginationHistory.length) {
        setPaginationHistory([...paginationHistory, result.lastVisible]);
      }
    } else if (currentPage === 1) {
      const result = await fetchItems(entity, null);
      setItems(result.data);
      setLastVisible(result.lastVisible);
      setLoading(false);
      setPaginationHistory([result.lastVisible]); // Reset pagination history
    }
  };

  useEffect(() => {
    fetchData(entity, lastVisible);
  }, [entity, currentPage]);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      setLastVisible(paginationHistory[newPage - 1]); // Use the previous page's lastVisible
    }
  };

  return (
    <div>
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <AutoResizeTextarea text={item.ideaLabel} />
          </li>
        ))}
      </ul>
      <div>
        {currentPage > 1 && <button onClick={goToPreviousPage}>&lt;--</button>}
        {items.length === 10 && !loading && (
          <button onClick={goToNextPage}>--&gt;</button>
        )}
      </div>
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default PaginatedList;

// CHATGPT FINAL ATTEMPT FOR NOW BELOW...
// import React, { useState, useEffect, useCallback } from "react";
// import readFromDb from "./readFromDb"; // Adjust the path to your Firebase functions
// import AutoResizeTextarea from "./AutoResizeTextarea";
// import "./PaginatedList.css";

// const PaginatedList = ({ entity }) => {
//   const [items, setItems] = useState([]);
//   const [lastVisible, setLastVisible] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [paginationHistory, setPaginationHistory] = useState([]);

//   const fetchItems = useCallback(async (entity, lastVisible) => {
//     setLoading(true);
//     try {
//       const result = await readFromDb(entity, lastVisible);
//       return result;
//     } catch (error) {
//       console.error("Error fetching items:", error);
//       return { data: [], lastVisible: null };
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   const resetAndFetch = useCallback(async () => {
//     const result = await fetchItems(entity, null);
//     setItems(result.data);
//     setLastVisible(result.lastVisible);
//     setPaginationHistory([result.lastVisible]);
//   }, [entity, fetchItems]);

//   useEffect(() => {
//     resetAndFetch();
//   }, [entity, resetAndFetch]);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (currentPage > 1) {
//         const lastDoc = paginationHistory[currentPage - 2];
//         const result = await fetchItems(entity, lastDoc);
//         setItems(result.data);
//         setLastVisible(result.lastVisible);

//         if (currentPage > paginationHistory.length) {
//           setPaginationHistory((prevHistory) => [
//             ...prevHistory,
//             result.lastVisible,
//           ]);
//         }
//       } else if (currentPage === 1) {
//         const result = await fetchItems(entity, null);
//         setItems(result.data);
//         setLastVisible(result.lastVisible);
//         setPaginationHistory([result.lastVisible]);
//       }
//     };

//     fetchData();
//   }, [currentPage, entity, fetchItems, paginationHistory]);

//   const goToNextPage = () => {
//     setCurrentPage((prevPage) => prevPage + 1);
//   };

//   const goToPreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   return (
//     <div>
//       <ul>
//         {items.map((item) => (
//           <li key={item.id}>
//             <AutoResizeTextarea text={item.ideaLabel} />
//           </li>
//         ))}
//       </ul>
//       <div>
//         {currentPage > 1 && !loading && (
//           <button onClick={goToPreviousPage}>&lt;--</button>
//         )}
//         {items.length === 10 && !loading && (
//           <button onClick={goToNextPage}>--&gt;</button>
//         )}
//       </div>
//       {loading && <p>Loading...</p>}
//     </div>
//   );
// };

// export default PaginatedList;

// CHAT GPT FULL ARROWS WORKING NO LIVE UPDATES
// import React, { useState, useEffect, useCallback } from "react";
// import readFromDb from "./readFromDb"; // Adjust the path to your Firebase functions
// import AutoResizeTextarea from "./AutoResizeTextarea";
// import "./PaginatedList.css";

// const PaginatedList = ({ entity }) => {
//   const [items, setItems] = useState([]);
//   const [lastVisible, setLastVisible] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [paginationHistory, setPaginationHistory] = useState([]);

//   const fetchItems = useCallback(async (entity, lastVisible) => {
//     setLoading(true);
//     try {
//       const result = await readFromDb(entity, lastVisible);
//       setItems(result.data);
//       setLastVisible(result.lastVisible);
//       return result;
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   useEffect(() => {
//     const resetAndFetch = async () => {
//       setItems([]);
//       setLastVisible(null);
//       setCurrentPage(1);
//       setPaginationHistory([]);

//       const result = await fetchItems(entity, null);
//       setPaginationHistory([result.lastVisible]);
//     };

//     resetAndFetch();
//   }, [entity, fetchItems]);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (currentPage === 1) {
//         const result = await fetchItems(entity, null);
//         setItems(result.data);
//         setLastVisible(result.lastVisible);
//         setPaginationHistory([result.lastVisible]);
//       } else {
//         let lastDoc = paginationHistory[currentPage - 2];
//         const result = await fetchItems(entity, lastDoc);

//         if (currentPage > paginationHistory.length) {
//           setPaginationHistory((prevHistory) => [
//             ...prevHistory,
//             result.lastVisible,
//           ]);
//         }
//       }
//     };

//     fetchData();
//   }, [currentPage, entity, fetchItems]);

//   const goToNextPage = () => {
//     setCurrentPage((prevPage) => prevPage + 1);
//   };

//   const goToPreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   return (
//     <div>
//       <ul>
//         {items.map((item) => (
//           <li key={item.id}>
//             <AutoResizeTextarea text={item.ideaLabel} />
//           </li>
//         ))}
//       </ul>
//       <div>
//         {currentPage > 1 && !loading && (
//           <button onClick={goToPreviousPage}>&lt;--</button>
//         )}
//         {items.length === 10 && !loading && (
//           <button onClick={goToNextPage}>--&gt;</button>
//         )}
//       </div>
//       {loading && <p>Loading...</p>}
//     </div>
//   );
// };

// export default PaginatedList;
