import React, { useRef, useEffect } from 'react';
import "./App.css";


const AutoResizeTextarea = ({ text }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset textarea height to auto to calculate the correct scroll height
      textarea.style.height = 'auto';
      // Set the height to the scroll height of the textarea
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [text]); // Depend on text prop to trigger the effect when it changes

  return (
    <textarea
      ref={textareaRef}
      value={text}
      disabled
      rows={1} // Start with at least one row
      style={{ overflow: 'hidden' }} // Hide the scroll bar
      className="ideaListItem"
      type="text"                               
      maxLength="114"
    />
  );
};

export default AutoResizeTextarea;
