// TO DO - autocomplete - DONE
// TO DO - Google generated image for entity text
// TO DO - thumbs up and count feature for each idea
// TO DO - find a way to keep server online at all times and refresh
//         without interrupting data every minute (may cost money)
// formerly <ul class="textbox-list"> where it's now list-group-item
// auto suggest feature need to include (already have code)
// buy domain and transfer to it
// get blaze plan -- DONE
// get serve for auto suggest -- DONE
// Load 25 ideas at a time

// li :  className="list-group-item"
// ul : boostrap class: className="list-group list-unstyled"
import "./App.css";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  limit,
} from "firebase/firestore";
import axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import AutoResizeTextarea from "./AutoResizeTextarea";
import PaginatedList from "./PaginatedList";
import readFromDb from "./readFromDb";

const firebaseConfig = {
  apiKey: "AIzaSyCaeiwcoarLst2nv3eCCWBUib_F2XpQlIE",
  authDomain: "ideahub-b2f21.firebaseapp.com",
  databaseURL: "https://ideahub-b2f21-default-rtdb.firebaseio.com",
  projectId: "ideahub-b2f21",
  storageBucket: "ideahub-b2f21.appspot.com",
  messagingSenderId: "16551158092",
  appId: "1:16551158092:web:41d029ee6a17b870deefe9",
  measurementId: "G-HB6BBJ9GZ6",
};

// Initialize Firebase
const application = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(application);

export { db };

function App() {
  const [childFunc, setChildFunc] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const registerChildFunction = useCallback((func) => {
    setChildFunc(() => func);
  }, []);

  const [entity, setEntity] = useState("");
  const ideaRef = useRef(null);
  const [fetchedData, setFetchedData] = useState([]);
  const buttonRef = useRef(null);

  // starts here Google Gemini code (+ import axios above)
  const [data, setData] = useState([]);

  const [itemClicked, setItemClicked] = useState(false);

  const [focusedIndex, setFocusedIndex] = useState(-1); // -1 indicates the input box
  const inputRef = useRef(null);
  const itemRefs = useRef([]);

  // const handleInputFocus = () => {
  //   setFocusedIndex(-1); // Ensure the focus index is reset when input is focused
  // };

  useEffect(() => {
    const dataSliced = data
      .filter((item) => {
        const searchTerm = entity;
        const termFromDb = item;
        return termFromDb.startsWith(searchTerm);
        // if filtereddata is 1, termfromdb and searchterm, then dont show it in filtereddata
      })
      .slice(0, 5);
    //console.log("filtered data from slice", filteredData);
    if (entity.trim() !== "") {
      setFilteredData(dataSliced);
    } else {
      setFilteredData([]);
    }
  }, [data]);
  // old working code (almost) below
  // Filter and slice the data
  // const dataSliced = data
  //   .filter((item) => {
  //     const searchTerm = entity;
  //     const termFromDb = item;
  //     return termFromDb.startsWith(searchTerm);
  //   })
  //   .slice(0, 5);
  //   //console.log("filtered data from slice", filteredData);
  //   setFilteredData(dataSliced);
  // Handle focus change based on arrow key presses
  useEffect(() => {
    if (focusedIndex === -1) {
      inputRef.current.focus();
    } else if (focusedIndex >= 0 && focusedIndex < filteredData.length) {
      itemRefs.current[focusedIndex].focus();
    }
  }, [focusedIndex, filteredData.length]);

  const handleEntityKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        Math.min(prevIndex + 1, filteredData.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (e.key === "Enter") {
      if (focusedIndex >= 0 && focusedIndex < filteredData.length) {
        onItemClick(filteredData[focusedIndex]);
      }
    }
    // else if (e.key === "Backspace") {
    //   e.preventDefault(); // Prevent the default backspace action
    //   if (focusedIndex >= 0) {
    //     // If an innerDiv is focused, move focus back to the input and handle backspace
    //     setFocusedIndex(-1);
    //     inputRef.current.focus();
    //     setEntity((prevValue) => prevValue.slice(0, -1));
    //   } else {
    //     // Handle backspace when input is focused
    //     setEntity((prevValue) => prevValue.slice(0, -1));
    //   }
    // }
  };

  const onItemClick = (item) => {
    setItemClicked(true);
    setEntity(item);
    //readFromDb(item); do I need this since entity updates calls readfrom Db?
    setFilteredData([]);
    //setItemClicked(false);
    ideaRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  /* async function fetchData() {
    const response = await axios.get("https://ideahub-b2f21.web.app/data");
    //const response = await axios.get("http://localhost:3000/data");
    console.log("response", response);
    console.log("response.data", response.data);
    console.log("response.data.variable", response.data.variable);
    console.log("response.variable", response.variable);
    //setData(response.data.variable);
    setData(response.data.variable);
  }*/

  // does useEffect need to be used below? Or can I just call fetchData();
  // you need useEffect because fetchData calls setData
  // which updates the data variable which means it gets filtered
  // and mapped dagain based on new input which was updated via setEntity

  // removed below useeffect which was working properly but for performance removed
  // useEffect(() => {
  //   if (!itemClicked) {
  //     fetchData();
  //   }
  // }, [entity]);

  // ends here

  //   const suggestions = [
  //     { label: "apple" },
  //     { label: "banana" },
  //     { label: "orange" },
  //   ];

  const handleInputChange = (event) => {
    setEntity(event.target.value.toLowerCase());
    setItemClicked(false);
  };

  useEffect(() => {
    // if entity matches iwth item, then setitemclicked to true?
    // if entity change from click, then setitemclicked to true
    // if entity changed from typing, then setitemclicked to false
    console.log("itemClicked value before fetchData function", itemClicked);
    async function fetchData() {
      // You can await here
      let response = null;
      try {
        response = await axios.get("https://ideajagah.com/data");
      } catch (error) {
        //const response = await MyAPI.getData(someId);
        // ...
        console.error("error from try catch", error);
      }
      if (response.data.variable) {
        //   console.log("response", response);
        // console.log("response.data", response.data);
        // console.log("response.data.variable", response.data.variable);
        // console.log("response.variable", response.variable);
        setData(response.data.variable);
      } else {
        setData([]);
      }
    }
    if (entity.trim() !== "") {
      //setItemClicked(false);
      //console.log("filterederror after this?");
      readFromDb(entity); // do I need await here? UPDATE: Added await on day of adding pagination
      console.log("itemclicked before fetch called", itemClicked);

      // if filtered data includes entity, and entity was clicked, then setitemclicked(true)
      // if filtered d
      if (!itemClicked) {
        // added if statement
        fetchData(); // added this and removed below
        //setItemClicked(false); // added this to be inside if statement.
      } else {
        setFilteredData([]);
      } // await fetchData gives proper response but not without n is not a function
      //setItemClicked(false); //removed this because idk why it's there
    } else {
      setFilteredData([]);
    }
  }, [entity]);
  //else {  setData already set up above?
  //setData([]); // used to be setFetchedData but removed because fetchedData is nowhere in program
  //}

  /* useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios.get("https://ideahub-b2f21.web.app/data");
      //const response = await MyAPI.getData(someId);
      // ...
      console.log("response", response);
    console.log("response.data", response.data);
    console.log("response.data.variable", response.data.variable);
    console.log("response.variable", response.variable);
      setData(response.data.variable);
    }
    fetchData();
  }, [entity]);*/ // Or [] if effect doesn't need props or state

  const handleClick = async () => {
    const value = ideaRef.current.value;
    if (value.trim() !== "") {
      // Do something with the value (e.g., console.log)
      //console.log("Input value:", value);
      await writeToDb(entity, value); // should i put await here? // remove async if not needed
    }
    ideaRef.current.value = "";
    ideaRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      buttonRef.current.click(); // Simulate click on button
    }
  };

  // const keyPressedInSearchResults = (event) => {
  //   // arrow keys, select, and show highlighted
  //   if (event.keyCode === 13) {
  //     // enter key
  //     searchButtonRef.current.click(); // Simulate click on button
  //   }
  // };

  async function writeToDb(entity, idea) {
    if (entity.trim() !== "" && idea.trim() !== "") {
      //console.log(idea);
      try {
        const docRef = await addDoc(collection(db, entity), {
          ideaLabel: idea,
          timestamp: serverTimestamp(),
        });
        // console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        // console.error("Error adding document: ", e);
      }
      await readFromDb(entity); // maybe remove await? // should I put await here?
      if (childFunc) {
        childFunc(entity, null);
      }
    }
  }

  //   async function getCollections() {
  //     const collections = await db.listCollections();
  //     console.log(collections);
  //   }

  const [isFocused, setIsFocused] = useState(false);
  const divRef = useRef(null);

  const handleFocus = () => {
    setFocusedIndex(-1); // Ensure the focus index is reset when input is focused
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ideaRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  // useEffect(() => {
  //   if (isFocused && divRef.current) {
  //     divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // }, [isFocused]);

  return (
    <div className="App">
      <div className="container">
        <img src="/blob.png" />
        <div className="stayStill" ref={divRef}>
          <div onKeyDown={handleEntityKeyDown} tabIndex="0">
            <form onSubmit={handleSubmit}>
              <input
                id="searchBox"
                type="text"
                value={entity}
                onChange={handleInputChange}
                placeholder="Who's your idea for?"
                ref={inputRef}
                onFocus={handleFocus} // Ensure focus index is reset when input is focused
                onBlur={handleBlur}
              />
            </form>
            <div className="dropdown">
              {filteredData &&
                filteredData.map((item, index) => (
                  <div
                    className="dropdown-row"
                    onClick={() => onItemClick(item)}
                    ref={(el) => (itemRefs.current[index] = el)}
                    key={index}
                    tabIndex="0"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onItemClick(item);
                      }
                    }}
                  >
                    {item}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="bottom">
          <div>
            {entity !== "" && (
              <div className="list">
                <textarea
                  id="newBox"
                  ref={ideaRef}
                  type="text"
                  placeholder="Enter your idea"
                  onKeyDown={handleKeyDown}
                  maxLength="114"
                  rows="3"
                />
                <p></p>
                <button ref={buttonRef} onClick={handleClick}>
                  Add Idea
                </button>
              </div>
            )}
          </div>
          <div className="body">
            <div>
              {entity !== "" && (
                <div className="list">
                  <br></br>
                  <div className="list">
                    <PaginatedList
                      entity={entity}
                      registerChildFunction={registerChildFunction}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
