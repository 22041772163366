import {
  collection,
  getDocs,
  orderBy,
  query,
  startAfter,
  limit,
} from "firebase/firestore";
import { db } from "./App"; // Adjust the path to your Firebase configuration

export async function readFromDb(entity, lastVisible = null) {
  console.log("Entity:", entity);
  if (!entity) {
    console.error("Error: Collection path (entity) is empty.");
    return { data: [], lastVisible: null };
  }

  let querySnapshot = null;
  const queryConstraints = [orderBy("timestamp", "desc"), limit(10)];

  if (lastVisible) {
    console.log("Using lastVisible for pagination:", lastVisible);
    queryConstraints.push(startAfter(lastVisible));
  }

  try {
    const collectionRef = collection(db, entity);
    querySnapshot = await getDocs(query(collectionRef, ...queryConstraints));
  } catch (error) {
    console.log("Error fetching documents:", error);
    return { data: [], lastVisible: null };
  }

  if (querySnapshot && !querySnapshot.empty) {
    const docs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    console.log("Fetched documents:", docs);
    console.log("New lastVisible:", newLastVisible);

    return { data: docs, lastVisible: newLastVisible };
  }

  return { data: [], lastVisible: null };
}

export default readFromDb;
